/* eslint-disable max-lines */
import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { PaginationOptions, NavigationOptions } from 'swiper/types';
import {
	MOBILE_MAX_WIDTH,
	EXTRA_LARGE_WIDTH,
	ADULT_BASE_FRAME_HANDLES,
	KIDS_BASE_FRAME_HANDLES,
	NET_NEW_ACETATE,
} from '@constants';
import { Accordian, Button, Chevron, Flex, Img, Loading, Paragraph, SwatchController, Tags, Title } from '@components';
import { FRAME_COLORS } from '@utils/index';
import { NormalizedProduct } from '@ts/product';
import { useBaseFrameProducts } from '@services/shopify';
import styles from './Compare.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const DEFAULT_SWIPER_PROPS = {
	direction: 'horizontal' as SwiperProps['direction'],
	wrapperTag: 'ul',
	slidesPerView: 1,
	spaceBetween: 0,
	pagination: {
		clickable: false,
		type: 'bullets',
		bulletElement: 'button',
		el: '.swiper-pagination',
		bulletClass: styles['swiper-pagination-bullet'],
		bulletActiveClass: styles['swiper-pagination-bullet-active'],
	} as PaginationOptions,
	a11y: {
		containerMessage: 'Image carousel',
		prevSlideMessage: 'Previous slide',
		nextSlideMessage: 'Next slide',
		lastSlideMessage: 'This is the last slide; it loops back to the first slide',
	},
	keyboard: { enabled: false },
	zoom: false,
};

type CompareProps = {
	color: string;
	product: NormalizedProduct;
	isOpen?: boolean;
	onClick?: () => void;
	isAccordian?: boolean;
	showTags?: boolean;
	showSwatches?: boolean;
	callback?: ({ option, type }: { option: string; type: 'frame' | 'lens' | 'option' }) => void;
	isPlp?: boolean;
};

const Compare = ({
	color,
	product,
	isOpen = false,
	onClick,
	isAccordian = true,
	showTags = false,
	showSwatches = false,
	callback,
	isPlp = false,
}: CompareProps) => {
	const isNetNewAcetate = useFeatureIsOn('is-net-new-acetate');
	const router = useRouter();
	const { demo } = router?.query;
	const [slidesPerView, setSlidesPerView] = useState(5);
	const [activeSlide, setActiveSlide] = useState(0);
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const swiperRef = useRef(null);

	const { data: baseFrameProducts = [], isLoading: isBaseFrameLoading } = useBaseFrameProducts();
	const updateBaseFrameProducts =
		isNetNewAcetate && color !== 'Blue Clear'
			? baseFrameProducts
			: baseFrameProducts.filter(
					({ name }) => !NET_NEW_ACETATE.includes(name.split(' ')[1] as (typeof NET_NEW_ACETATE)[number])
				);

	const handleSlideChange = useCallback(
		swiper => {
			setActiveSlide(swiper.activeIndex);
		},
		[setActiveSlide]
	);

	const handleSlideClick = useCallback(
		index => {
			if (!swiperRef) return;
			swiperRef.current.swiper.slideTo(index);
			setActiveSlide(index);
		},
		[setActiveSlide]
	);

	useEffect(() => {
		const handleResize = () => {
			const viewportWidth = window.innerWidth;
			if (viewportWidth <= MOBILE_MAX_WIDTH) {
				setSlidesPerView(1.5);
			} else if (viewportWidth <= EXTRA_LARGE_WIDTH) {
				setSlidesPerView(3.5);
			} else {
				setSlidesPerView(5);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const frameVariants = useMemo(
		() =>
			updateBaseFrameProducts.map(
				({
					availableForSale,
					id,
					images,
					options,
					price,
					slug,
					totalInventory,
					variants,
					variantImages,
					name,
					metafields,
					path,
					handle,
					tags,
					type,
				}) => {
					return {
						availableForSale,
						id,
						images,
						options,
						price,
						slug,
						totalInventory,
						variants,
						name,
						image: variantImages?.[color]?.[0],
						frameWidth: metafields?.frameSizings?.frame?.width,
						unit: metafields?.frameSizings?.units,
						path,
						handle,
						tags,
						type,
					};
				}
			),
		[baseFrameProducts, color]
	);

	const sortedFrameVariants = frameVariants.sort((a, b) => a.frameWidth - b.frameWidth);
	const initialSlide = sortedFrameVariants.findIndex(({ handle }) => handle === product.handle);

	const swiperOptions = { ...DEFAULT_SWIPER_PROPS, initialSlide };

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const handleProductLink = handle => {
		let category = demo;
		if (handle in KIDS_BASE_FRAME_HANDLES) category = 'kids';
		if (handle in ADULT_BASE_FRAME_HANDLES && demo === 'kids') category = 'women';

		setTimeout(() => {
			window.location.href = `/eyeglasses/${category}/${handle}?frameColor=${color}`;
		}, 1000);
	};

	const mappedComparisonImgs =
		sortedFrameVariants &&
		sortedFrameVariants.map(({ name, image, frameWidth, unit, handle }, i) => {
			const swiperClasses = cn(styles['swiper-slide'], {
				[styles['swiper-slide-active']]: activeSlide === i,
				[styles['swiper-slide-inactive']]: activeSlide !== i,
			});

			const contentContainerClasses = cn(styles.contentContainer, {
				[styles['active-slide-with-tags']]: activeSlide === i && showTags,
			});

			return (
				<SwiperSlide
					key={`slide-${i}`}
					className={swiperClasses}
					onClick={() => handleSlideClick(i)}
					{...(isPlp ? { 'data-plp-compare-base-frame': name } : { 'data-compare-base-frame': name })}
				>
					<Img src={image?.url} alt={image?.altText} />
					<Flex column align='center' className={contentContainerClasses}>
						{activeSlide === i && showTags && (
							<Tags
								containerType='horizontal'
								compact={false}
								product={sortedFrameVariants[i]}
								lozengeData={null}
								customTags={null}
								showCollectionLozenge={false}
								className={styles.tagsContainer}
							/>
						)}
						<Title>{name}</Title>
						<Paragraph>{`${frameWidth} ${unit}`}</Paragraph>
						{activeSlide === i && (
							<>
								{isBaseFrameLoading ? (
									<Loading />
								) : (
									<div
										className={`${styles.selectedContainer} ${
											isAccordian && handle === product.handle && styles.checkmark
										}`}
									>
										{isAccordian && handle === product.handle ? (
											<Paragraph className={styles.selectedText}>Current selected</Paragraph>
										) : (
											<Button
												linkStyle
												size='small'
												label={isAccordian ? 'View Base Frame' : `View ${name}`}
												onClick={() => handleProductLink(handle)}
											/>
										)}
									</div>
								)}
							</>
						)}
					</Flex>
				</SwiperSlide>
			);
		});

	const renderLines = () => {
		const lines = [];
		for (let i = 0; i < 100; i++) {
			lines.push(<div className={styles.progressLine} key={`line-${i}`} />);
		}
		return lines;
	};

	const renderCompareContent = () => {
		if (!frameVariants.length) {
			return <Loading />;
		}

		return (
			<Flex className={styles.container} style={{ paddingTop: isAccordian ? '1.6rem' : '0.8rem' }}>
				<Flex className={styles.swiperContainer}>
					<Swiper
						autoplay={false}
						direction='horizontal'
						modules={[Navigation, Pagination, Zoom]}
						pagination={swiperOptions.pagination}
						zoom={swiperOptions.zoom}
						centeredSlides={true}
						grabCursor={true}
						navigation={navigationRefs}
						onBeforeInit={swiper => {
							(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
							(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
						}}
						onSlideChange={handleSlideChange}
						spaceBetween={16}
						effect='fade'
						fadeEffect={{ crossFade: true }}
						ref={swiperRef}
						{...swiperOptions}
						slidesPerView={slidesPerView}
						className={styles.swiper}
					>
						{mappedComparisonImgs}
						{isAccordian && (
							<>
								<div
									className={`${styles.prevButton} ${activeSlide === 0 ? styles.hide : ''}`}
									ref={navigationPrevRef}
								>
									<Chevron extraClasses={styles.chevron} direction='left' />
								</div>
								<div
									className={`${styles.nextButton} ${
										activeSlide + 1 >= sortedFrameVariants?.length ? styles.hide : ''
									}`}
									ref={navigationNextRef}
								>
									<Chevron extraClasses={styles.chevron} direction='right' />
								</div>
							</>
						)}
						<div className={styles.scaleContainer}>
							<Paragraph className={styles.progressText}>Narrowest</Paragraph>
							<div className={styles.progressBarContainer}>
								<div className={styles.progressLineContainer}>{renderLines()}</div>
								<div className={cn('swiper-pagination', styles['swiper-pagination'])} />
							</div>
							<Paragraph className={styles.progressText}>Widest</Paragraph>
						</div>
					</Swiper>
				</Flex>
			</Flex>
		);
	};

	return isAccordian ? (
		<Accordian title='Compare' isOpen={isOpen} onClick={onClick}>
			{renderCompareContent()}
		</Accordian>
	) : (
		<>
			{renderCompareContent()}
			<Flex justify={showSwatches ? 'between' : 'end'} style={{ padding: '2.4rem 0' }}>
				{showSwatches && (
					<SwatchController
						options={Object.values(FRAME_COLORS)}
						callback={callback}
						name={''}
						selected={color}
						type={'frame'}
						alignment='left'
						parent={'product-card'}
						showDescription
					/>
				)}
				<Flex gap={3}>
					<div
						className={`${styles.bottomPrevButton} ${activeSlide === 0 ? styles.disabled : ''}`}
						ref={navigationPrevRef}
					>
						<Chevron extraClasses={styles.chevron} direction='left' />
					</div>
					<div
						className={`${styles.bottomNextButton} ${
							activeSlide + 1 >= sortedFrameVariants?.length ? styles.disabled : ''
						}`}
						ref={navigationNextRef}
					>
						<Chevron extraClasses={styles.chevron} direction='right' />
					</div>
				</Flex>
			</Flex>
		</>
	);
};

export default Compare;
