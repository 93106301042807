import React, { memo, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { m } from 'framer-motion';
import { Checkbox, Flex, Heading, Img, Paragraph, Tooltip, TypographyButton } from '@components';
import { useRXSSContext } from '@context';
import client from '@services/contentful/client';
import {
	RXSS_FIELDS_SELECTION_COPY,
	RXSS_FIELDS_SUBTITLE_COPY,
	RXSS_FIELDS_TITLE_COPY,
	RXSS_FIELD_INPUT_IMAGE_ID,
	RXSS_FIELD_OPTIONS,
	RXSS_STEPS,
} from '@utils/constants/rxss';
import { RxForm, bulkResetRxFields } from '@ts/rxss';
import styles from './Fields.module.scss';

const Fields = () => {
	const {
		selectedFields,
		setSelectedFields,
		setIsPdFieldSelected,
		isPdFieldSelected,
		setIsNextStepBlocked,
		step,
		setHasTwoPds,
	} = useRXSSContext();
	const { setValue, getValues, reset } = useFormContext();

	const ContentfulAsset = useQuery(['rxss-field-input-image'], async () => {
		const fetchedAsset = await client.getAsset(RXSS_FIELD_INPUT_IMAGE_ID);
		return fetchedAsset.fields;
	});

	const sendFieldUpdate = useCallback(
		(field: keyof RxForm) => {
			if (selectedFields.includes(field)) {
				const prevSelectedFields = [...selectedFields];
				bulkResetRxFields(field, setValue);
				const values = getValues();
				reset(values);
				const index = prevSelectedFields.indexOf(field);
				prevSelectedFields.splice(index, 1);
				setSelectedFields(() => [...prevSelectedFields]);

				if (field === 'pd' && isPdFieldSelected) {
					setIsPdFieldSelected(false);
				}
				setHasTwoPds(false);
				return;
			}

			setSelectedFields(prevState => [...prevState, field]);
			if ([...selectedFields, field].includes('pd') && !isPdFieldSelected) {
				setIsPdFieldSelected(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedFields, setValue, isPdFieldSelected]
	);

	useEffect(() => {
		if (selectedFields.length === 1 && selectedFields.includes('pd')) {
			setIsNextStepBlocked(true);
			return;
		}
		setIsNextStepBlocked(selectedFields.length === 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFields]);

	return (
		<m.div
			animate={step === RXSS_STEPS.FIELDS ? 'inView' : 'outOfView'}
			initial={selectedFields.length > 0 ? 'outOfView' : 'inView'}
			transition={{ type: 'spring', stiffness: 256, damping: 24, duration: 0.25, mass: 1 }}
			variants={{
				inView: {
					x: 0,
					y: 0,
					opacity: 1,
				},
				outOfView: {
					x: '-125%',
					y: 0,
				},
			}}
			style={{ width: '100%' }}
		>
			<Flex column fullWidth fullHeight style={{ flexGrow: '1' }}>
				{ContentfulAsset.isFetched ? (
					<Img noSrcset src={ContentfulAsset.data.file.url} className={styles.image} />
				) : (
					<div className={styles.image} />
				)}
				<Flex
					column
					align='start'
					pad={4}
					gap={3}
					backgroundColor='white'
					noBorder
					className={styles['content-container']}
				>
					<Flex column align='start' gap={2} className={styles.fieldsCopy} fullWidth>
						<Heading tag='h6' style={{ margin: '0' }}>
							{RXSS_FIELDS_TITLE_COPY}
						</Heading>
						<Paragraph>{RXSS_FIELDS_SUBTITLE_COPY}</Paragraph>
					</Flex>
					<Flex align='end'>
						<TypographyButton small>{RXSS_FIELDS_SELECTION_COPY}</TypographyButton>
					</Flex>
					<Flex column align='start' gap={3} fullWidth>
						{Object.entries(RXSS_FIELD_OPTIONS).map(([option, { label, tooltip = null }]) => {
							return (
								<Checkbox
									key={`field-input-${option}`}
									option={option}
									handler={(field: keyof RxForm) => sendFieldUpdate(field)}
									className={styles.fieldOption}
									checked={selectedFields.includes(option as keyof RxForm)}
									dataTags={{
										[`data-rx-field`]: option,
									}}
								>
									<Flex fullWidth justify='between' align='center'>
										<Paragraph>{label}</Paragraph>
										{tooltip && <Tooltip message={tooltip} />}
									</Flex>
								</Checkbox>
							);
						})}
					</Flex>
				</Flex>
			</Flex>
		</m.div>
	);
};

export default memo(Fields);
