import { memo, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { BF_MY_TOPS_COPY, BUILD_FLOW_STEPS, BUILD_FLOW_STEPS_LENS_EXPANSION, MEDIUM_LARGE_WIDTH } from '@constants';
import { useIsLensExpansionTest, useIsMobile } from '@utils/hooks';
import { useBFContext } from '@context';
import { Caption, Flex, Heading, LabelText, Modal, MyTops, TypographyButton } from '@components';
import styles from './ProductView.module.scss';

const TopFrameEducation = dynamic(() => import('@components').then(mod => mod.TopFrameEducation));

const ProductView = () => {
	const { isLensExpansion } = useIsLensExpansionTest();
	const bfEnum = isLensExpansion ? BUILD_FLOW_STEPS_LENS_EXPANSION : BUILD_FLOW_STEPS;
	const { tops, product, variantImages, step, handleTop, isSubscriptionActivated, skipSubscription } = useBFContext();
	const isTablet = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const hasTops = Object.keys(tops).length !== 0;
	const name = product.name;
	const [modalOpen, setModalOpen] = useState(false);

	function handleModalChange(shouldOpen: boolean) {
		setModalOpen(shouldOpen);
	}

	const memoizedTopFrameEducation = useMemo(() => <TopFrameEducation isMobile={isTablet} />, [isTablet]);
	const isTopFrameStep = step === bfEnum.TOP_FRAMES;
	const isNoPreviewMobile = isTablet && isTopFrameStep;
	const titleProductView = !isNoPreviewMobile ? name : 'Choose Top Frames';
	const showProductViewActions =
		!isSubscriptionActivated || (isSubscriptionActivated && skipSubscription) || !isTopFrameStep || !isNoPreviewMobile;

	return (
		<>
			<Flex
				column
				align='center'
				justify='between'
				className={cn(styles['product-view'], {
					[styles['--no-preview']]: isNoPreviewMobile,
				})}
				data-use-padding={!isTopFrameStep}
				data-use-height-tops-step={isTopFrameStep}
			>
				<Flex
					column
					fullWidth
					align='center'
					className={cn({
						[styles['no-preview']]: isNoPreviewMobile,
					})}
				>
					{showProductViewActions && (
						<Flex fullWidth justify='center' className={styles['preview-header']}>
							<Heading
								tag={isNoPreviewMobile ? 'h6' : 'h3'}
								style={{ marginTop: isNoPreviewMobile ? '0' : '1rem' }}
							>
								{titleProductView}
							</Heading>
							{isTopFrameStep && (
								<Modal open={modalOpen} onOpenChange={handleModalChange}>
									<Modal.Trigger>
										<Flex
											className={cn(styles['mobile-trigger'], {
												[styles['is-active']]: hasTops,
											})}
											align='center'
											justify='start'
										>
											{!hasTops ? (
												<img
													className={styles['list-placeholder-image']}
													src={'/empty-selection.gif'}
													// uncomment this prop and switch to `Img` component once BF refactor has been live for a few days
													// noSrcset
													alt='empty selection'
													width={60}
													height={32}
												/>
											) : (
												<img
													className={styles['mobile-image']}
													src={tops[Object.keys(tops)[0]].image.url}
													alt={'product'}
													width={64}
													height={32}
												/>
											)}
										</Flex>
										<Flex
											align='center'
											justify='center'
											className={cn(styles['mobile-count'], {
												[styles['is-active']]: hasTops,
											})}
										>
											<Caption style={{ fontWeight: 700, fontSize: '1.4rem' }}>
												{Object.keys(tops).length}
											</Caption>
										</Flex>
									</Modal.Trigger>
									<Modal.Content>
										<Flex align='center' justify='center' className={styles.header} fullWidth>
											<TypographyButton small>{hasTops ? 'My Tops' : BF_MY_TOPS_COPY}</TypographyButton>
										</Flex>
										<Flex
											column
											align='center'
											justify='center'
											fullWidth
											style={{ maxHeight: '40vh', height: '100%' }}
										>
											<MyTops tops={tops} onTrashClick={handleTop} />
										</Flex>
									</Modal.Content>
								</Modal>
							)}
						</Flex>
					)}
					{isNoPreviewMobile && showProductViewActions && <TopFrameEducation isMobile={isTablet} />}
					{!isNoPreviewMobile && (
						<div className={styles['image-container']}>
							<img
								className={styles['product-image']}
								src={variantImages[0].url}
								alt={'product'}
								width={variantImages[0].width}
								height={variantImages[0].height}
							/>
							{isTablet && memoizedTopFrameEducation}
						</div>
					)}
				</Flex>
			</Flex>
			{isTopFrameStep && (
				<Flex column fullWidth className={styles['my-tops']}>
					{!isNoPreviewMobile && <TopFrameEducation />}
					<Flex align='center' justify='center' className={styles.header} fullWidth>
						<div>
							<Flex align='center' justify='center'>
								<TypographyButton small>{hasTops ? 'My Tops' : BF_MY_TOPS_COPY}</TypographyButton>
							</Flex>
							{hasTops && (
								<Flex
									align='center'
									justify='center'
									className={cn(styles['tops-counter'], { [styles['is-active']]: hasTops })}
								>
									<LabelText style={{ fontWeight: 700, fontSize: '1.4rem' }}>
										{Object.keys(tops).length}
									</LabelText>
								</Flex>
							)}
						</div>
					</Flex>
					<Flex className={styles.body}>
						<MyTops tops={tops} onTrashClick={handleTop} />
					</Flex>
				</Flex>
			)}
		</>
	);
};

export default memo(ProductView);
